<template>
  <div id="app" :class="{ padding: $route.path !== '/' }">
    <!-- 头部 -->
    <PcHeader v-if="$route.path !== '/'"></PcHeader>
    <keep-alive>
      <router-view :key="$route.path + $route.query.t"></router-view>
    </keep-alive>
    <!-- 底部 -->
    <PcFooter></PcFooter>
  </div>
</template>

<script>
import PcHeader from './components/pcHeader/index'
import PcFooter from './components/pcFooter/index'
export default {
  components: {
    PcHeader,
    PcFooter
  },
  mounted() {
    // if (this.isMobile()) {
    //   this.$router.push('/phone')
    // } else {
    //   console.log(1123)
    //   this.$router.push('/')
    // }
    // let res = document.body.clientWidth
    // if(res <= 790){
    //   this.$router.push('/phone')
    // }else{
    //   window.location.hash = '/'
    // }
  },
  methods: {
    // isMobile() {
    //   var userAgentInfo = navigator.userAgent
    //   var mobileAgents = [
    //     'Android',
    //     'iPhone',
    //     'SymbianOS',
    //     'Windows Phone',
    //     'iPad',
    //     'iPod'
    //   ]
    //   var mobile_flag = false
    //   //根据userAgent判断是否是手机
    //   for (var v = 0; v < mobileAgents.length; v++) {
    //     if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
    //       mobile_flag = true
    //       break
    //     }
    //   }
    //   var screen_width = window.screen.width
    //   var screen_height = window.screen.height
    //   //根据屏幕分辨率判断是否是手机
    //   if (screen_width > 325 && screen_height < 750) {
    //     mobile_flag = true
    //   }
    //   return mobile_flag
    // }
  }
}
</script>

<style lang="less" scoped>
#app {
  // margin: 0px auto;
  // position: relative;
  // width: 1366px;
}
.padding {
  padding-top: 80px;
}
</style>
